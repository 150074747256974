<template>
	<div class="table-detail mb20">
		<el-form ref="form" :model="form" :inline="true" :rules="rules">
			<table>

				<tr v-if="starts == 0">
					<td style="width:70px">学号：</td>
					<td style="width:230px">
						<el-select v-model="studentInfo" filterable remote reserve-keyword placeholder="请输入关键词"
							:remote-method="remoteMethod" @change="studentChange" class="w180">
							<el-option v-for="item in searchStudentList" :key="item.id" :value="item.id"
								:label="item.nameNum">
							</el-option>
						</el-select>
					</td>
					<td style="width:70px">姓名：</td>
					<td style="width:230px"> {{ form.studentname.length > 0 ? form.studentname : '-' }} </td>
				</tr>

				<tr v-if="starts == 1">
					<td style="width:70px">院系：</td>
					<td style="width:230px">
						<el-select v-model="form.depid" placeholder="请选择" clearable @change="changeSystem">
							<el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</td>
					<td style="width:70px">班级：</td>
					<td style="width:230px">
						<el-select v-model="form.classid" placeholder="请选择" clearable>
							<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</td>
				</tr>
				<tr>
					<td>评分类型：</td>
					<td>
						<el-select v-model="form.scsid" placeholder="请选择" @change="changed_scoreCategory">
							<el-option v-for="item in scoreCategoryList" :key="item.id" :label="item.category"
								:value="item.id" />
						</el-select>
					</td>
					<td>评分明细：</td>
					<td>
						<el-select v-model="form.sctid" placeholder="请选择" @change="changed_scoreContent">
							<el-option v-for="item in scoreContentList" :key="item.sctid" :label="item.content"
								:value="item.sctid" />
						</el-select>
						<!-- <el-input v-model="form.sctid"></el-input> -->
					</td>
				</tr>
				<tr>
					<td>计分上限：</td>
					<td>{{ maxscore }}</td>
					<td>计分下限：</td>
					<td>{{ minscore }}</td>
				</tr>
				<tr>
					<td>课节时间：</td>
					<td>
						<el-select v-model="form.classdayid" placeholder="请选择" @change="changed_classdayid">
							<el-option v-for="item in projectList" :key="item.id"
								:label="item.project + ' ' + item.starttime + '到' + item.endtime" :value="item.id" />
						</el-select>
					</td>
					<td>评分分数：</td>
					<td>
						<el-input-number v-model="form.score" controls-position="right" :min="minscore" :max="maxscore">
						</el-input-number>
					</td>
				</tr>
				<tr>
					<td>补充说明：</td>
					<td colspan="3">
						<el-input v-model="form.explains" rows="5" type="textarea" style="resize:none"></el-input>
					</td>
				</tr>
				<tr>
					<td colspan="4">
						<el-button @click="cancel()"> 关 闭 </el-button>
						<el-button contenteditable="" type="primary"
							@click="dialogStatus == 'create' ? createData('form') : updateData('form')">
							保 存
						</el-button>
					</td>
				</tr>
			</table>
		</el-form>
	</div>
</template>

<script>
import * as api from "@/api/users";
export default {
	name: "addAssessment",
	props: {
		dialogStatus: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				page: 1,
				pageSize: 10,
				order: null,
				orderType: null,
				heightFlag: null,
				classFlag: null,
				id: null,
				scsid: null,
				sctid: null,
				score: 0,
				classdayid: null,
				explains: "",
				photo: "",
				starts: 0,
				classid: null,
				studentid: null,
				createtime: "",
				studentname: "",
				keyWord: null,
				classList: null,
				dataList: null,
				dateList: null,
				deleteid: 0,
				depid: null,
				startTime: null,
				cname: "",
				project: "",
				endTime: null,
				category: "",
				content: "",
				time: null,
				dname: "",
				classnum: null,
				assessmentdate: "",
				week: "",
				month: "",
				studentnum: "",
				operatorid: null,
				operatorname: "",
				operatornum: "",
				operatordepid: -1,
				operatordepname: null,
			}, // 被编辑的 数据项

			systemList: [], //院系列表
			classList: [], //班级列表

			starts: 0, // 学生0，班级1
			scoreCategoryList: [], // 评分类型 列表
			scoreContentList: [], // 评分明细 列表
			projectList: [], // 课节时间 列表
			minscore: -10, // 最小分数
			maxscore: 10, // 最大分数

			returnImgUrl: "",
			readonly: true,

			// 搜索条件
			studentInfo: "",
			searchStudentList: [], //搜索学生列表

			// 表单校验规则
			rules: {
				// 没有进行任何输入时，不会触发change，但一定会触发blur事件
				scsid: [{ required: true, trigger: "blur", message: '评分类别不能为空！' }],
				sctid: [{ required: true, trigger: "blur", message: '评分明细不能为空！' }],
				score: [{ required: true, trigger: "blur", message: '评分分数不能为空！' }],
			},

		};
	},
	// 也是 一种监听，跟 watch 有相似性
	computed: {},
	// vue 使用 watch 监听 <input v-model="name" 的 值变化
	// watch 监控的变量当然必须在data里面声明才可以
	watch: {
		// 第一种方式
		// name (val) {
		//   this.nameCopy = val;
		// }
		// 第二种方式 2-1
		// name: 'nameChange'
	},
	// vue 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
	created() {
		this.load();
	},
	// vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
	mounted() {
		this.getSystemList();
	},
	methods: {
		// 获取院系列表
		getSystemList() {
			let {
				page,
				pageSize
			} = this;
			let params = {
				page,
				pageSize,
			};
			// 院系接口
			api.getSystemList(params).then((res) => (this.systemList = res.data.records));
		},

		// 切换院系
		changeSystem(id) {
			api.getCardArgumentDetail({
				depid: id
			}).then((res) => {
				let classList = res.data.records.filter((item) => item.depid === id);
				this.classList = classList;
				this.classid = null;
				// 取消 默认选中 第一个班级
				// if (classList != null && classList.length > 0) {
				//  this.form.classid = classList[0].id;
				// }
			});
		},

		// 智能 搜索 学员
		getStudentList(val) {
			api.selectBySearchName({
				searchName: val,
				searchType: '1',
			}).then((res) => {
				if (res.status === 1) {
					let arr = res.data.data;
					if (arr != null) {
						arr.forEach((res) => {
							res.nameNum = res.num + " " + res.name;
						});
						this.searchStudentList = arr;
					}
				}
			});
		},
		// 姓名/学号
		remoteMethod(query) {
			this.getStudentList(query); // 调用上面的方法
		},
		studentChange(id) {
			for (let i = 0; i < this.searchStudentList.length; i++) {
				if (this.searchStudentList[i].id == id) {
					// this.form.studentId = this.searchStudentList[i].id;
					this.form.studentid = this.searchStudentList[i].id;
					this.form.studentnum = this.searchStudentList[i].num;
					this.form.studentname = this.searchStudentList[i].name;
					break;
				}
			}
		},

		// 修改 课节时间
		changed_classdayid(id) {
			for (let i = 0; i < this.projectList.length; i++) {
				if (this.projectList[i].id == id) {
					// this.form.studentid = this.projectList[i].id;
					this.form.project = this.projectList[i].project;
					this.form.starttime = this.projectList[i].starttime;
					this.form.endtime = this.projectList[i].endtime;
					break;
				}
			}
		},

		// 第二种方式 2-2
		// nameChange () {
		//   this.nameCopy = this.name;
		// }
		// Step 2 根据 评分类型ID 获取 评分明细列表
		changed_scoreCategory(value) {
			// this.form.scsid = value; // 经过测试：不需要重新赋值的
			this.refresh_scoreContentList(value);
		},
		refresh_scoreContentList(value) {
			let params = {
				scsid: value, // 评分类型ID
				starts: this.starts,// 状态 0为学生指标  1为班级指标
			};
			// 读取 指标 列表 (评分明细列表)
			api.getScoreContentList(params).then((res) => {
				this.scoreContentList = res.data.rows;
				// ----------- 异步执行 -----------
				// Step 4 改选
				// this.form.sctid = sctid;
				this.changed_scoreContent(this.form.sctid)
			});
		},
		// el-select 的 @change 事件
		changed_scoreContent(value) {
			// this.form.sctid = value; // 经过测试：不需要重新赋值的
			this.scoreContentList.forEach(element => {
				if (element.sctid == value) {
					this.minscore = element.minscore; // 最小分数
					this.maxscore = element.maxscore; // 最大分数
					this.form.score = element.score; // 默认值
					return;
				}
			});
		},
		load() { },
		// 关闭（调用底层容器的 方法 changeDialogFormVisible）
		cancel() {
			this.$emit("changeDialogFormVisible", false);
		},
		getTrue() {
			return true;
		},
		getFalse() {
			return false;
		},

		// ----------------------
		// 保存 新增的 数据
		createData(form) {
			this.$refs[form].validate((valid) => {
				if (valid) {
					let a = this.form;
					api.insertAssessmentDetail(a).then((res) => {
						console.log(res);
						if (res.status == 1) {
							// 显示成功信息
							this.$message.success(res.msg);
							// 隐藏窗口
							this.$emit("changeDialogFormVisible", false);
						} else {
							// 显示错误信息
							this.$message.error(res.msg);
						}
					});
				}
			});
		},

		// ----------------------
		// 保存 修改后的 数据
		updateData() {
			console.log(this.form);
			console.log("修改", this.dialogStatus);
			let backItem = this.form;
			// 保存 编辑
			api.updateAssessmentDetail(backItem).then((res) => {
				console.log(res);
				if (res.status == 1) {
					// 显示成功信息
					this.$message.success(res.msg);
					// 隐藏窗口
					this.$emit("changeDialogFormVisible", false);
				} else {
					// 显示错误信息
					this.$message.error(res.msg);
				}
			});
		},

		// ----------------------
		// 显示 新增|编辑 记录
		showAddData(starts) {
			// Step 1 先赋值
			this.starts = starts;
			this.form.starts = starts;

			// Step 2 获取 评分类别 列表
			api.getScoreCategoryList({
				page: this.page,
				pageSize: this.pageSize,
				starts: this.starts, // 学生0，班级1
			}).then((res) => {
				this.scoreCategoryList = res.data.records;
			});

			// Step 2 获取 课节时间 列表
			api.getAssessmentTimeList({
				page: this.page,
				pageSize: this.pageSize,
				starts: this.starts, // 学生0，班级1
			}).then((res) => {
				this.projectList = res.data.records;
			});

			if (this.form.scsid == null || this.form.scsid <= 0) {
				if (this.scoreCategoryList.length > 0) {
					this.form.scsid = this.scoreCategoryList[0].id;
				}
			}

			// Step 3 根据 评分类型ID 获取 评分明细列表
			this.refresh_scoreContentList(this.form.scsid);
		},
	},
};
</script>

<style lang="scss" scoped>
.table-detail {
	padding: 16px 0;
	margin: 0 32px;

	table {
		width: 100%;
		border: 1px solid #d7dae2;
		border-collapse: collapse;

		td {
			width: calc(30% - 10px);
			text-align: left;
			padding: 12px 8px;
			border: 1px solid #d7dae2;

			&:nth-child(2n + 1) {
				width: calc(20% - 10px);
				text-align: right;
				background: #eceef3;
			}
		}
	}
}
</style>
