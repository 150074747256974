<template>
  <div class="table-detail">
    <table>
      <tr v-for="item in detailData" :key="item.label">
        <td>{{ item.leftLabel }}：</td>
        <td>{{ item.leftValue }}</td>
        <td>{{ item.rightLabel }}：</td>
        <td>{{ item.rightValue }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["detailData"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-detail {
  padding: 16px 0;
  margin: 0 32px;
  table {
    width: 100%;
    border: 1px solid #d7dae2;
    border-collapse: collapse;
    td {
      width: calc(30% - 10px);
      text-align: left;
      padding: 12px 8px;
      border: 1px solid #d7dae2;
      &:nth-child(2n + 1) {
        width: calc(20% - 10px);
        text-align: right;
        background: #eceef3;
      }
    }
  }
}
</style>
